/*
* See documentation: https://vue-multiselect.js.org/
*/
import Multiselect from 'vue-multiselect'

export default {
  props: {
    caret: {
      type: [Boolean, String],
      default() {
        return require('@icon/down-chevron.svg?raw')
      },
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [Object, Array, String, Number],
      required: true,
    },
  },
  components: { Multiselect },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
    onClose() {
      this.open = false
      this.$emit('close')
    },
    onOpen() {
      this.open = true
      this.$emit('open')
    },
    onSelect(value) {
      this.$emit('select', value)
    },
  },
}
